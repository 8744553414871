.body {
  font-family: var(--font-lato);
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
}

.body input,
.body button {
  font-family: var(--font-lato);
}

.body input {
  font-family: var(--font-lato);
  color: var(--color-text-primary);
  font-size: 0.875rem;
}

.body input:placholder {
  font-family: var(--font-lato);
  letter-spacing: 0.06rem;
  font-size: 0.875rem;
}

.body input:focus {
  outline: none;
  border: 1px solid var(--color-text-input-border-focus);
}

.body input::placeholder {
  font-family: var(--font-lato);
}

.body label {
  font-family: var(--font-lato);
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: 700;
  color: var(--color-text-secondary);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: var(--content-min-height);
  font-family: var(--font-lato);
  margin: 0 auto;
}

.quaternary-bg {
  background-color: var(--color-bg-quaternary);
}

.content :global(.page-heading) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 170px;
  font-size: 1.2rem;
  max-width: 100vw;
  box-sizing: border-box;
}

@media (max-width: min(100vw, 1040px)) {
  .content :global(.page-heading) {
    text-align: center;
  }
}

.content :global(.page-heading) h1 {
  color: var(--color-text-strong);
  font-size: 2rem;
}

.content :global(.page-heading) :global(.subtitle) {
  color: var(--color-text-primary);
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.limitWidth {
  width: calc(100% - 300px);
}

@media (max-width: 1040px) {
  .limitWidth {
    width: 100%;
  }
}

.content :global(.page-content) {
  width: 100%;
  row-gap: 40px;
  padding: 40px 0;
  box-shadow: 0px -8px 20px rgba(50, 50, 50, 0.05);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content :global(.page-content) > * {
  max-width: 800px;
}
