html[data-theme='pm-dark'] {
  /* Theme */
  --color-primary: #010a28;
  --color-secondary: #041040;
  --color-tertiary: #19275e;
  --color-quaternary: #082aaf;
  --color-glass: rgba(255, 255, 255, 0.1);
  --color-glass-secondary: rgba(255, 255, 255, 0.75);
  --background-image-url: url('/static/share/images/background.svg');

  /* Text */
  --color-text-primary: #d7d4da;
  --color-text-secondary: #808493;
  --color-text-strong: #ffffff;
  --color-text-action: #72f5c8;
  --color-text-destructive: #ff7c60;
  --color-text-error: #9e260d;
  --color-text-sidebar: #d7d4da;
  --color-text-sidebar-strong: #ffffff;

  /* Accents */
  --color-accent-action-light: #72f5c8;
  --color-accent-action-medium: #38ecae;
  --color-accent-action-dark: #00c17e;
  --color-accent-destructive-light: #ff7c60;
  --color-accent-destructive-medium: #d1482b;
  --color-accent-destructive-dark: #9e260d;
  --color-accent-primary-light: #a0799b;
  --color-accent-primary-medium: #8f5186;
  --color-accent-primary-dark: #782c68;
  --color-accent-secondary-light: #779ac3;
  --color-accent-secondary-medium: #466991;
  --color-accent-secondary-dark: #224d7e;

  /* Personality */
  --color-personality: #779ac3;

  /* Background Color */
  --color-bg-primary: #010a28;
  --color-bg-secondary: #041040;
  --color-bg-tertiary: #19275e;
  --color-bg-quaternary: #082aaf;
  --color-bg-footer: #0d283b;
  --color-bg-sidebar-active-item: rgba(255, 255, 255, 0.1);
  --color-bg-drawer: #010a28;
  --color-bg-panel: rgba(255, 255, 255, 0.1);
  --color-bg-panel-quaternary: rgba(255, 255, 255, 0.1);
  --color-bg-panel-drawer: #e3e3e3;
  --color-bg-panel-header: #01293d;
  --color-bg-panel-header: #01293d;
  --color-bg-table: #010a28;
  --color-bg-table-row: #010a28;
  --color-bg-table-header: none;
  --color-bg-modal: #010a28;
  --color-bg-grid: none;
  --color-bg-filter-sidebar: #041040;
  --color-bg-filter-sidebar-section: #19275e;
  --color-bg-filter-sidebar-header: #010a28;
  --color-bg-filter-sidebar-footer: #010a28;

  /* Shadows */
  --primary-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
  --button-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
  --grid-item-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
  --card-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.25);

  /* Checkbox Input */
  --color-checkbox-border: #8d919e;
  --color-checkbox-unchecked: #1b233e;
  --color-checkbox-checked: #72f5c8;
  --color-checkbox-hovered: #041040;
  --color-checkbox-hovered-border: #041040;
  --color-check: #020a28;
  --color-check-hovered: #1b2853;

  /* Text Input */
  --color-text-input-bg: #1c233e;
  --color-text-input-border: #323951;
  --color-text-input-border-seondary: rgba(255, 255, 255, 0.1);
  --color-text-input-bg-secondary: rgba(255, 255, 255, 0.1);
  --color-text-input-border-focus: #082aaf;
  --color-text-input-border-error: #9e260d;
  --color-text-input-bg-disabled: #0f1633;

  /* Buttons */
  --color-button-primary-bg: #72f5c8;
  --color-button-primary-bg-hovered: #00c17e;
  --color-button-primary-bg-active: #38ecae;
  --color-button-primary-text: #010a28;
  --color-button-primary-text-hovered: #ffffff;
  --color-button-primary-text-active: #010a28;
  --color-button-secondary-bg: #010a28;
  --color-button-secondary-bg-hovered: #00c17e;
  --color-button-secondary-bg-active: #38ecae;
  --color-button-secondary-text: #72f5c8;
  --color-button-secondary-text-hovered: #ffffff;
  --color-button-secondary-text-active: #010a28;
  --color-button-secondary-border: #72f5c8;
  --color-button-secondary-border-hovered: #00c17e;
  --color-button-cancel-bg: #ff7c60;
  --color-button-cancel-bg-hovered: #9e260d;
  --color-button-cancel-bg-active: #d1482b;
  --color-button-cancel-text: #020a28;
  --color-button-cancel-text-hovered: #020a28;
  --color-button-destructive-bg: #ff7c60;
  --color-button-destructive-bg-hovered: #9e260d;
  --color-button-destructive-bg-active: #d1482b;
  --color-button-destructive-text: #020a28;
  --color-button-destructive-text-hovered: #020a28;
  --color-button-disabled-bg: #19275e;
  --color-button-disabled-text: #020a28;
  --color-button-spinner-bg: #72f5c8;
  --color-button-spinner-text: #010a28;
  --color-button-sustainable-bg: #f7f7f7;
  --color-button-sustainable-bg-hovered: #f7f7f7;
  --color-button-sustainable-bg-active: #e7f7fe;
  --color-button-success: #72f5c8;
  --color-playbook-button-bg: #1b233e;
  --color-playbook-button-bg-hovered: #466991;
  --color-playbook-button-text: #d7d4da;
  --color-button-sustainable-border: #e3e3e3;
  --color-button-sustainable-border-hovered: #e3e3e3;
  --color-button-sustainable-border-active: #2ca7e4;
  --color-button-sustainable-text: #6c6c6c;
  --color-button-marketing-bg: #f09024;
  --color-button-marketing-bg-hovered: #01293d;
  --color-button-marketing-bg-active: #01293d;
  --color-button-marketing-text: #ffffff;
  --color-button-marketing-text-hovered: #ffffff;
  --color-button-marketing-text-active: #80949e;

  --spacing-button-padding-x: 16px;
  --spacing-button-padding-y: 8px;

  /* Navigation */
  --color-subnav-bg: #1b233e;
  --color-pagination-current: #8f5186;
  --color-pagination-hovered: #38ecae;
  --color-pagination-character: #466991;

  /* Avatars */
  --color-avatar-fg: #bfc3cf;
  --color-avatar-bg: #041040;

  /* Sizing */
  --side-nav-width: 300px;
  --side-nav-mini-width: 75px;
  --secondary-nav-width: 225px;
  --nav-height: 86px;
  --new-profile-page-width: 1473px;
  --content-min-height: calc(100vh - var(--nav-height));
  --main-content-width: min(100vw, 1040px);
  --min-new-profile-width: 1200px;
  --mobile-width: 940px;
  --tablet-width: 1040px;

  /* Tooltip */
  --color-tooltip-bg: #031040;
  --color-tooltip-text: #81869f;

  /* Loading Bar */
  --color-loading-bar: #19275e;
  --color-loading-bar-shimmer: #4a5c9f;

  /* Chip */
  --color-chip-bg: #779ac3;
  --color-chip-bg-error: #912f1b;
  --color-chip-bg-success: #57be83;
  --color-chip-text: #010a28;

  /* Dev */
  --magenta: #ff00ff;

  /* DISC */
  --color-disc-empty-glass: rgba(255, 255, 255, 0.1);
  --color-disc-empty: #72f5c8;
  --color-disc-line: #d7d4da;

  /* Icons */
  --color-icon-small: #779ac3;
  --color-icon-large: #d7d4da;

  /* Link */
  --color-link: #72f5c8;

  /* Dos and Donts */
  --color-do: #11b21b;
  --color-dont: #db3c48;

  /* COMPONENTS */
  /* Tooltip */

  .tooltip {
    position: relative;
    cursor: pointer;
  }

  .tooltip .tooltip-content {
    position: absolute;
    left: 50%;
    bottom: calc(100% + 10px);
    z-index: 100000;
    background-color: var(--color-tooltip-bg);
    color: var(--color-tooltip-text);
    width: 100vw;
    max-width: 20rem;
    text-align: center;
    filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.2));
    box-sizing: border-box;
    font-size: 1rem;
    line-height: 1.5em;
    opacity: 0;
    transition: all 0.2s;
    border-radius: 4px;
    padding: 6px 14px;
    pointer-events: none;
    transform: translate(-50%, 50%) scale(0, 0);
    font-weight: normal;
  }

  .tooltip .tooltip-content::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-width: 10px;
    border-style: solid;
    border-color: var(--color-tooltip-bg) transparent transparent transparent;
    left: calc(50% - 10px);
    top: 100%;
  }

  /* Global Styles */
  .tooltip.bottom-tooltip .tooltip-content {
    transform: translate(-50%, 0) scale(0, 0);
    bottom: initial;
    top: calc(100% + 10px);
  }

  .tooltip.bottom-tooltip .tooltip-content::after {
    border-color: transparent transparent var(--color-tooltip-bg) transparent;
    top: -20px;
  }

  .tooltip.bottom-left-tooltip .tooltip-content {
    transform: translate(-95%, 0) scale(0, 0);
    bottom: initial;
    top: calc(100% + 10px);
  }

  .tooltip.bottom-left-tooltip .tooltip-content::after {
    border-color: transparent transparent var(--color-tooltip-bg) transparent;
    top: -20px;
    left: calc(95% - 10px);
  }

  .tooltip.right-tooltip .tooltip-content {
    left: calc(100% + 10px);
    bottom: initial;
    top: 50%;
    transform: translate(0, -50%) scale(0, 0);
  }

  .tooltip.right-tooltip .tooltip-content::after {
    border-color: transparent var(--color-tooltip-bg) transparent transparent;
    left: -20px;
    top: calc(50% - 10px);
  }

  .tooltip.left-tooltip .tooltip-content {
    transform: translate(-95%, 0) scale(0, 0);
    bottom: initial;
    top: 50%;
  }

  .tooltip.left-tooltip .tooltip-content::after {
    border-color: transparent transparent transparent var(--color-tooltip-bg);
    left: calc(100% - 1px);
    top: calc(50% - 10px);
  }

  .tooltip:hover .tooltip-content {
    opacity: 1;
    transform: translate(-50%, 0) scale(1, 1);
  }

  .tooltip.bottom-tooltip:hover .tooltip-content {
    transform: translate(-50%, 0) scale(1, 1);
  }

  .tooltip.bottom-left-tooltip:hover .tooltip-content {
    transform: translate(-95%, 0) scale(1, 1);
  }

  .tooltip.right-tooltip:hover .tooltip-content {
    transform: translate(0, -50%) scale(1, 1);
  }

  .tooltip.left-tooltip:hover .tooltip-content {
    transform: translate(-95%, -50%) scale(1, 1);
  }
}
