:root {
  /* DISC Colors */
  --disc-D: #db3c48;
  --disc-Di: #e5561f;
  --disc-DI: #f48119;
  --disc-Id: #ffb727;
  --disc-I: #f7cf0d;
  --disc-Is: #d3e000;
  --disc-IS: #afd80a;
  --disc-Si: #88c100;
  --disc-S: #11b21b;
  --disc-Sc: #51b48e;
  --disc-SC: #00b6bc;
  --disc-Cs: #007fb6;
  --disc-C: #2f5fa5;
  --disc-Cd: #6756b2;
  --disc-CD: #93359b;
  --disc-Dc: #c62e85;

  /* DISC light */
  --disc-D-light: #ffeeee;
  --disc-Di-light: #fff1e9;
  --disc-DI-light: #fef6f4;
  --disc-Id-light: #fffceb;
  --disc-I-light: #fff8e2;
  --disc-Is-light: #fffdf1;
  --disc-IS-light: #fffee6;
  --disc-Si-light: #fffce0;
  --disc-S-light: #f7fdf7;
  --disc-Sc-light: #f2fdf8;
  --disc-SC-light: #f0fdfe;
  --disc-Cs-light: #e4f5fc;
  --disc-C-light: #d3e5f7;
  --disc-Cd-light: #d8d7eb;
  --disc-CD-light: #e9ddee;
  --disc-Dc-light: #f6f7fa;

  /* DISC very light */
  --disc-D-very-light: #fbeced;
  --disc-Di-very-light: #fceee9;
  --disc-DI-very-light: #fef2e8;
  --disc-Id-very-light: #fff8e9;
  --disc-I-very-light: #fcf8e6;
  --disc-Is-very-light: #fbfbe6;
  --disc-IS-very-light: #f7fbe7;
  --disc-Si-very-light: #f3f9e6;
  --disc-S-very-light: #e7f7e8;
  --disc-Sc-very-light: #eef7f4;
  --disc-SC-very-light: #e6f8f8;
  --disc-Cs-very-light: #e6f2f8;
  --disc-C-very-light: #eaeff6;
  --disc-Cd-very-light: #f0eef7;
  --disc-CD-very-light: #f4ebf5;
  --disc-Dc-very-light: #f9eaf3;

  /* DISC glass */
  --disc-D-glass: rgba(219, 60, 72, 0.15);
  --disc-Di-glass: rgba(219, 60, 72, 0.15);
  --disc-DI-glass: rgba(244, 129, 25, 0.15);
  --disc-Id-glass: rgba(255, 183, 39, 0.15);
  --disc-I-glass: rgba(229, 190, 0, 0.15);
  --disc-Is-glass: rgba(214, 214, 5, 0.15);
  --disc-IS-glass: rgba(175, 216, 10, 0.15);
  --disc-Si-glass: rgba(136, 193, 0, 0.15);
  --disc-S-glass: rgba(17, 178, 27, 0.15);
  --disc-Sc-glass: rgba(81, 180, 142, 0.15);
  --disc-SC-glass: rgba(0, 182, 188, 0.15);
  --disc-Cs-glass: rgba(0, 127, 182, 0.15);
  --disc-C-glass: rgba(47, 95, 165, 0.15);
  --disc-Cd-glass: rgba(103, 86, 178, 0.15);
  --disc-CD-glass: rgba(147, 53, 155, 0.15);
  --disc-Dc-glass: rgba(198, 46, 133, 0.15);
}
